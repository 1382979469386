import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/Header"
import { menu, offers, row1, row2, system1, system3, system2 } from "../mock"
import { Link } from "gatsby"
import Footer from "../components/Footer/Footer"


/**
 *
 * background-image: url(/static/players-2369e22….png);
 background-position: center 12%;
 background-repeat: no-repeat;
 background-color: transparent;
 background-size: 47%;
 mix-blend-mode: luminosity;
 width: 100%;
 *
 *
 * **/

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil"/>

    <Header
      withActivity={true}>
      <div className={"home-container"}>
        <div className={"home-header"}>
          <div>

            <p>
              {
                "Boostez votre activité,\n"
              }
            </p>
            <p>
              simplifiez-vous la vie
            </p>
            <p>
              avec le logiciel tout-en-un
            </p>
            <div className={"search-bar"}>
              <input type={"text"} placeholder={"Saisissez votre email  pour avoir des infos"}/>
              <button>envoyer</button>
            </div>
          </div>
          <div className={"home-img"}>
            <img src={require("../images/new/players.svg")} alt={""}/>
          </div>
        </div>

        <div className={"home-menu"}>
          {menu.map((it, i) => (
            <Link to={"#" + it.url} key={"small-index-menu-" + i}>
              <div
                className={"small-menu"}
                style={{ background: it.background }}
              >
                <img
                  src={require("../images/" + it.url + ".svg")}
                  alt={it.name}
                />
                <span>{it.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Header>

    <div
      className={"page-container"}
    >
      <p className={"page-description lg-screen"}>
      Solution clé en main pour le Sports & Loisirs <br/>
      réunissant toutes vos procédures de gestion en une seule plateforme.
    </p>
      <p className={"page-description small-screen"}>
        Solution clé en main <br/> pour le Sports & Loisirs <br/>
        réunissant toutes vos procédures de gestion en une seule plateforme.
      </p>
      <div className={"offer-items"}>
        {offers.map((it, i) => {
          return (
            <div className={"offer-item"} key={"offer-" + i}>
              <img src={require("../images/new/" + it.icon)} alt={it.title}/>
              <span>{it.title}</span>
            </div>
          )
        })}
      </div>

      <p className={"page-description page-description-display"}>
        {"Découvrez notre écosystème"
        }
      </p>

      <img alt={""}
           id={"side1"}
           src={require("../images/bg/side1.svg")}/>

      <img alt={""}
           id={"side2"}
           src={require("../images/bg/side2.svg")}/>

      <div className={"system-items"}>
        {system1.map((it, i) => {
          return (
            <img key={it.image} src={require("../images/overflow/" + it.img)} alt={""}/>
          )
        })}
      </div>

      <div className={"system-items"}>
        {system2.map((it, i) => {
          return (
            <img key={it.image} src={require("../images/overflow/" + it.img)} alt={""}/>

          )
        })}
      </div>

      <div className={"system-items"}>
        {system3.map((it, i) => {
          return (
            <img key={it.image} src={require("../images/overflow/" + it.img)} alt={""}/>
          )
        })}
      </div>

      <div id={"reservation"} className={"info-page margin-top"}>

        <img alt={""}
             id={"side3"}
             src={require("../images/bg/side3.svg")}/>

        <img
          id={"money"}
          src={require("../images/overflow/bg-time.svg")} alt={"bg"} className={"img-background"}/>


        <div className={"info-img"}>
          <img src={require("../images/content/Planning.png")}

               alt={"reservation"}/>
        </div>


        <div className={"info-description"} style={{ margin: "auto" }}>


          <img src={require("../images/overflow/calendar.svg")}
               alt={""}
               id={"calendar"}
          />


          <img src={require("../images/overflow/trophy.svg")}
               alt={""}
               id={"trophy"}
          />

          <h1 style={{color:"#B228B5"}}>Gagnez du temps</h1>
          <div>
            <span>Simplifier la gestion de votre complexe</span>
            <p>
              Notre solution de gestion vous accompagnera au quotidien dans votre activité.
            </p>
            <p>
              De la réservation à l’encaissement en passant par la gestion des abonnements tout est fait pour vous faire
              gagnez du temps. </p>
          </div>
        </div>

      </div>

      <div id={"crm"} className={"info-page margin-top"}>


        <img alt={""}
             id={"side4"}
             src={require("../images/bg/side4.svg")}/>

        <img src={require("../images/overflow/bg-pilot.svg")}
             id={"pilot"}
             alt={"bg"} className={"img-background"}/>


        <div className={"info-description"} style={{ display: "flex", alignItems: "center" }}>

          <img src={require("../images/overflow/hand.svg")}
               alt={""}
               id={"hand"}
          />

          <img src={require("../images/overflow/crm.svg")}
               alt={""}
               id={"crm-img"}
          />

          <h1 style={{color:"#1378E5"}}>Pilotez</h1>
          <div>
            <span style={{ color: "#1378E5" }}>Un outil de pilotage de votre activité</span>
            <p style={{ width: "100%" }}>
              Base de données qualifiée, statistiques en temps réelle, une plateforme et une application dédiée aux
              manager pour suivre 24h/24 leurs centres.
            </p>
            <br/>
            <p style={{ width: "100%" }}>
              Tout est réuni pour vous aider dans vos prises de décisions.
            </p>
          </div>
        </div>

        <div className={"info-img"}>
          <img src={require("../images/content/client.png")}
               style={{}}
               alt={"client"}/>
        </div>
      </div>


      <div id={"innov-c"} className={"info-page margin-top"}>
        <img src={require("../images/overflow/innov.svg")}
             id={"innov"}
             alt={"bg"} className={"img-background"}/>


        <div className={"info-description"} style={{ display: "flex", alignItems: "center" }}>

          <img src={require("../images/content/card.svg")}
               alt={""}
               id={"card"}
          />

          <img src={require("../images/content/coll.svg")}
               alt={""}
               id={"coll"}
          />

          <img src={require("../images/content/camera.svg")}
               alt={""}
               id={"camera"}
          />


          <h1 style={{color:"#1CD8D2"}}>Innovez</h1>
          <div style={{ marginLeft: "5rem" }}>
            <span style={{ color: "#1CD8D2" }}>Développez votre activité sans efforts</span>
            <p style={{ width: "100%" }}>
              Notre écosystème vous permet d’offrir à vos clients une réelle plus value
              et les incitent à revenir chez vous.
            </p>
            <br/>
            <p style={{ marginTop: 0 }}>
              - Simplifier l’organisation des matchs
            </p>
            <p style={{ marginTop: 0 }}>
              - Statistiques de jeu
            </p>
            <p style={{ marginTop: 0 }}>
              - Vidéo live & Replay
            </p>
            <p style={{ marginTop: 0 }}>
              - Scorer Digital
            </p>
          </div>
        </div>

        <div className={"info-img"}>
          <img src={require("../images/content/web-2.png")}
               style={{}}
               alt={"client"}/>
        </div>
      </div>


      <button className={"btn-demo"}>Demandez une démonstration</button>


    </div>

    <Footer>
      <div className={"home-menu"} style={{ alignItems: "center", justifyContent: "center" }}>
        {menu.map((it, i) => (
          <Link to={"#" + it.url} key={"small-index-menu-" + i}>
            <div
              className={"small-menu"}
              style={{ background: it.background }}
            >
              <img
                src={require("../images/" + it.url + ".svg")}
                alt={it.name}
              />
              <span>{it.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </Footer>
  </Layout>
)

export default IndexPage
